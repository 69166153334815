import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { v4 as uuidv4 } from 'uuid';

const sessionId = uuidv4();
let lp;
// async function postData(userdata) {
//   try {
//     const response = await fetch('https://mmflow.ru/api', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({'userdata': userdata})
//     });
//     const data = await response.json();
//     console.log(data);
//   } catch (error) {
//     console.log('Error:', error);
//   }
// }

// postData('234234243')


// let tg = window.Telegram;

// if (tg !== undefined) {
//   if (tg.WebApp !== undefined && tg.WebApp.initData !== "") {
//     // tg.WebApp.requestFullscreen();
//     // tg.WebApp.allow_vertical_swipe = false
//   }
// }



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App sessionId={sessionId} lp={lp}/>
  </React.StrictMode>
);

(function() {
  var { retrieveLaunchParams, postEvent } = window.telegramApps.sdk;
  lp = retrieveLaunchParams();


  // alert(lp.initData.user.id);
  // postData(lp.initData.user.id);
  fetch("https://mmflow.ru/api", {
    "method": "POST",
    "headers": {
          "Content-Type": "application/json; charset=utf-8"
    },
    "body": JSON.stringify({'app':'quiz_1224','session':sessionId,'result':-1,'initData':lp})
  })
  .then((res) => res.text())
  .then(console.log.bind(console))
  .catch(console.error.bind(console));
  
  // Некоторые версии Telegram не требуют классов выше.
  if (['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(lp.platform)) {
    return;
  }
  // Расширить приложение.
  // postEvent('web_app_expand');
  postEvent('web_app_request_fullscreen')

  document.body.classList.add('mobile-body');
  document.getElementById('wrap').classList.add('mobile-wrap');
  document.getElementById('content').classList.add('mobile-content');
})();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
