
import './App.scss';
import Quiz from './components/Quiz/Quiz';

function App({ sessionId, lp }) {
  return (
<>
<Quiz sessionId={sessionId} lp={lp}/>
</>
  );
}

export default App;
