import React, { useState } from "react";
import ProgressBar from "./ProgressBar";
import QuestionWrapper from "./QuestionWrapper";
import StartScreen from "./StartScreen";
import ResultScreen from "./ResultScreen";
import questions from "../../utils/questionData";
import "./Quiz.scss";
import bgVideo from "./bg.mp4"; // Импорт видео

const Quiz = ({sessionId, lp}) => {
  const [currentScreen, setCurrentScreen] = useState("start"); // Экран: start, quiz, result
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});

  // var guid = () => {
  //   var w = () => { 
  //     return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); 
  //   }
  //   return  `${w()}${w()}-${w()}-${w()}-${w()}-${w()}${w()}${w()}`;
  // }
  // const url_to_poster = guid + '.png'
  // localStorage.setItem('url_to_poster', url_to_poster.value);
  

  const handleStart = () => {
    
    setCurrentScreen("quiz");
  }
  const handleAnswer = (questionId, answer) => {
    setAnswers({ ...answers, [questionId]: answer });
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setCurrentScreen("result");
    }
  };

  return (
    <div className="quiz-wrapper">
      <video
        className="background-video"
        autoPlay
        loop
        muted
        playsInline
        src={bgVideo} // Используем импортированный файл
      ></video>
      <div className="quiz-container">
        {currentScreen === "start" && <StartScreen onStart={handleStart} />}
        {currentScreen === "quiz" && (
          <>
            <ProgressBar
              current={currentQuestionIndex + 1}
              total={questions.length}
            />
            <QuestionWrapper
              question={questions[currentQuestionIndex]}
              onAnswer={handleAnswer}
            />
          </>
        )}
        {currentScreen === "result" && (
          <ResultScreen answers={answers} questions={questions} sessionId={sessionId} lp={lp}/>
        )}
      </div>
    </div>
  );
};

export default Quiz;




